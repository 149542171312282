export {
  Title,
  Tip,
  shallowIntl,
  createIntl,
  createSetterContent,
  // TODO: To be deleted
  obx,
  computed,
  observer,
  getSetter,
  getSettersMap,
  globalLocale,
} from '@alilc/lowcode-editor-core';